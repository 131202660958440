import "./style.css";
import { Card, CardBody, CardImg, CardText, Button, Row, Col, Container, Input} from 'reactstrap';
import ImageUploader from '../ImageUploader';
import axios from "axios";
import React, { useState,useEffect,useRef } from 'react';
import API from "../../utils/API";
import noImage from '../../assets/images/no-image.jpg'
const KoobloQuestion = (props) => {
    const apiKey = process.env.REACT_APP_UNSPLASH_API_KEY;
    const [question,setQuestion] = useState(props.question)
    const [editToggle, setEditToggle] = useState(false);
    const [previewImage, setPreviewImage] = useState(null);
    const [files, setFiles] = useState([]);
    const [ImgApiModal, setImgApiModal] = useState(false);
    const [apiImages, setApiImages] = useState([]);
    const [URLModal, setURLModal] = useState(false);
    const [imageApiDesc, setimageApiDesc] = useState("");
    const [apiPageCount, setApiPageCount] = useState(1);
    const [imageURL, setimageURL] = useState("");
    const [questionEdit, setQuestionEdit] = useState('');
    const [answerEdit, setAnswerEdit] = useState('');
    const [deleteToggle, setDeleteToggle] = useState(false)
    const [loading, setLoading] = useState(false);
    const modalBodyRef = useRef(null);
  
  
    const fileInput = useRef(null);

    const toggleImgApiModal = () => setImgApiModal(!ImgApiModal);
    const toggleURLModal = () => setURLModal(!URLModal);
    
    useEffect(()=>{
    },[])
    const onImageApiDescChange = e =>{
        setimageApiDesc(e.target.value)
        setApiPageCount(1);
    }
    const selectImageAPI = async image => {
        try {
            // Trigger Unsplash download
            await axios.get(image.links.download_location, {
                headers: {
                    Authorization: `Client-ID ${apiKey}`
                }
            });
            // Update state
            setImgApiModal(!ImgApiModal);
            setPreviewImage(image.urls.small);
            setFiles(image.urls.small);
    
        } catch (error) {
            console.error("Error triggering image download:", error);
        }
    };

    const searchImageApi = () => {
        setLoading(true);
        setApiImages([]); // Reset the images array
        setApiPageCount(1); // Reset to the first page
        axios.get(`https://api.unsplash.com/search/photos`, {
            params: {
                query: imageApiDesc,
                page: apiPageCount,
                per_page: 20,
            },
            headers: {
                Authorization: `Client-ID ${apiKey}`
            }
        }).then(res => {
                // Handle the response
                setApiImages(res.data.results);
                setApiPageCount(2);
            })
            .catch(error => {
                console.error('Error fetching images from Unsplash:', error);
            })
            .finally(() => {
                setLoading(false);
            });

      };
      useEffect(() => {
        if (ImgApiModal) {
          setLoading(false);
        }
      }, [ImgApiModal]);

      useEffect(() => {
        const handleScroll = (event) => {
            const ApiImgBody = event.target;
            if (ApiImgBody.scrollTop + ApiImgBody.clientHeight >= ApiImgBody.scrollHeight - 5 && !loading) {
                setLoading(true);
                fetchImages();
            }
        };

        const ApiImgBody = document.getElementById('ApiImgBody');
        if (ApiImgBody) {
            ApiImgBody.addEventListener('scroll', handleScroll);
            return () => {
                ApiImgBody.removeEventListener('scroll', handleScroll);
            };
        }
      }, [ImgApiModal, loading, ]);
      const fetchImages = () => {
        axios.get(`https://api.unsplash.com/search/photos`, {
            params: {
                query: imageApiDesc,
                page: apiPageCount,
                per_page: 20,
            },
            headers: {
                Authorization: `Client-ID ${apiKey}`
            }
        }).then(res => {
                // Handle the response
                setApiImages(prevImages => [...prevImages, ...res.data.results]);
                setApiPageCount(prevPageCount => prevPageCount + 1);
            })
            .catch(error => {
                console.error('Error fetching images from Unsplash:', error);
            })
            .finally(() => {
                setLoading(false);
            });
    };
    // end api image search
    const onFileChange = e => {
        if(e.target.files.length !== 0){
            setPreviewImage(URL.createObjectURL(e.target.files[0]))
            setFiles(e.target.files);
        }
    }
    const onURLChange = e =>{
        setimageURL(e.target.value);
    }
    const handleURLSubmit = e => {
        setPreviewImage(imageURL);
        setFiles(imageURL);
        setURLModal(!URLModal);
    }
  
    const onEditToggleClick = ()=>{
      setEditToggle(!editToggle);
      setQuestionEdit(question.question);
      setAnswerEdit(question.answer);
      if(question.image){
        setFiles(question.image);
        setPreviewImage(question.image);
      }else{
        setFiles(null);
        setPreviewImage(null);
      }
    }
    //INVALID IMAGE CALLBACK
    const invalidImage = e =>{
        console.log("invalid image")
    }

    const onQuestionChange = e =>{
        setQuestionEdit(e.target.value);
    }
    const onAnswerChange = e =>{
        setAnswerEdit(e.target.value);
    }

    const onSaveClick = ()=>{
        let data = {...question}
            data.question = questionEdit;
            data.answer=answerEdit;
            data.files=files;
        API.editQuestion(data).then(res=>{
            setQuestion(res.data[0]);
            setEditToggle(!editToggle);
            }).catch(err=>{
            console.log(err);
          })
    
    }
    const onCancelClick =()=>{
        setEditToggle(!editToggle);
    }
    const onDeleteToggle =()=>{
        setDeleteToggle(true);
    }
    //REMOVE PREVIEW IMAGE ALLOW UPLOAD OF A NEW IMAGE
    const removePreviewImage = e =>{
        setPreviewImage("");
        setFiles(null);
    }
    
  return (
    <Card className="kq-question-card">
        {deleteToggle?
        <div >

            <div className='h-100 align-content-center text-center min-height-200'>
            <i className="fa-solid fa-trash-can"></i>
            <p className="titan-one-regular p-0 m-0" >Are you sure?</p>
            </div>
            <div className="class-link-group nunito">
                <p className="class-card-custom-link class-card-custom-link-delete" onClick={props.onDeleteClick}>Yes</p>
                <p className="class-card-custom-link " onClick={()=>setDeleteToggle(false)}>No</p>
            </div>
        </div>

        // </Row>
        :

        <Row className="h-100 min-height-200 m-0">
            
            <Col md="4" className="image-col kq-col-4 d-flex align-items-center p-0">
                {editToggle?
                    (      previewImage?
                        <div>
                        <img src={previewImage} className="preview-image m-0 p-0" onError={()=>invalidImage()}></img>
                        <button onClick={removePreviewImage} className="remove-preview-image">Remove</button>
                        </div>
            
                        :      <ImageUploader
                                setPreviewImage={setPreviewImage}
                                setFiles={setFiles}
                                toggleImgApiModal={toggleImgApiModal}
                                ImgApiModal={ImgApiModal}
                                onImageApiDescChange={onImageApiDescChange}
                                apiImages={apiImages}
                                setApiImages={setApiImages}
                                selectImageAPI={selectImageAPI}
                                searchImageApi={searchImageApi}
                                fileInput={fileInput}
                                onFileChange={onFileChange}
                                URLModal={URLModal}
                                toggleURLModal={toggleURLModal}
                                onURLChange={onURLChange}
                                handleURLSubmit={handleURLSubmit}
                                apiPageCount={apiPageCount}
                                setApiPageCount={setApiPageCount}
                                loading={loading}
                                modalBodyRef={modalBodyRef}
                            />
                    )
                :
                <CardImg src={question.image ? question.image:noImage} alt="Question Image" className="card-img-zoom" />

                }

            </Col>
            <Col md="8" className="d-flex flex-column kq-col-8 p-0">
                <CardBody className="d-flex flex-column justify-content-between p-0">
                    <Container>
                        {editToggle ?
                            <Row>
                                <Col md="6">
                                <CardText className="text-center"><strong>Question:</strong> </CardText>
                                <Input type="textarea"  rows="4" id="questionText"  placeholder = "Question" defaultValue={questionEdit} onChange={e=>onQuestionChange(e)}/>
                                </Col>

                                <Col md="6" >
                                <CardText className="text-center"><strong>Answer:</strong></CardText>
                                <Input type="textarea" rows="4"  name="text"  placeholder = "Answer" id="answerText" defaultValue={answerEdit} onChange={e=>onAnswerChange(e)}/>
                                </Col>
                            </Row>
                            :
                            <Row className="text-center">
                                <Col md="6">
                                <CardText className="text-center"><strong>Question:</strong> </CardText>
                                <p>
                                {question.question}
                                </p>
                                </Col>

                                <Col md="6" >
                                <CardText className="text-center"><strong>Answer:</strong></CardText>
                                <p>
                                {question.answer}
                                </p>
                                </Col>
                            </Row>
                        }
                    </Container>
                    {editToggle?
                        <div className="kq-link-group nunito text-center">
                            <p className="kq-card-custom-link text-center player-card-add-button" onClick={onSaveClick}>Save</p>
                            <p className="kq-card-custom-link text-center player-card-minus-button" onClick={onCancelClick}>Cancel</p>                  
                        </div>
                    :
                        <div className="kq-link-group nunito text-center">
                            <p className="kq-card-custom-link text-center player-card-add-button" onClick={onEditToggleClick}>Edit</p>
                            <p className="kq-card-custom-link text-center player-card-minus-button" onClick={onDeleteToggle}>Delete</p>                  
                        </div>

                    }
                </CardBody>
            </Col>
        </Row>
    }
</Card>

);
};


export default KoobloQuestion;