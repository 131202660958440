// success.js
import React, { useEffect, useState, useContext } from 'react';
import axios from 'axios';
import { Container, Row, Col, Card, Button } from 'reactstrap';
import PropTypes from 'prop-types';
import { AuthContext } from '../AuthContext/AuthContext';
const apiUrl = process.env.REACT_APP_API_URL;

const SubscriptionUpdate = ({ selectedPlan, currentPlan }) => {
    const [subscriptionData, setSubscriptionData] = useState(null);
    const { user, setUser } = useContext(AuthContext);

    const handleUpdateSubscription = async () => {
        console.log('hello')
        const priceIdMap = {
            Annual: 'price_1Q9tZKDwwfybDM0zxojIduE5',
            Monthly: 'price_1Q9syyDwwfybDM0zlTeuTubw',
        };

        const selectedPriceId = priceIdMap[selectedPlan];

        try {
            // Make sure to point to your backend server's URL
            const response = await axios.post(`${apiUrl}/update-subscription`, {
                planId: selectedPriceId,
                planType: selectedPlan,  // Include planType ("Annual" or "Monthly") in the request body
            }, { withCredentials: true });

            if (response.data.success) {
                // Optionally update user state or notify user about successful update
                setUser(response.data.updatedUser); // Assuming the updated user data is returned
            } else {
                throw new Error(response.data.message);
            }
        } catch (error) {
            console.error('Error updating subscription:', error);
        }
    };

    // Simulate different plan data
    const plans = {
        Free: {
            name: 'Free Plan',
            price: '$0/month',
            details: [
                'Create up to 10 question sets',
                'Access basic Kooblo games',
                'No access to Kooblo Plus Games',
                'No access to future Kooblo updates'
            ]
        },
        Annual: {
            name: 'Yearly Plan',
            price: '$4.99/month ($59.99 Billed Annually)',
            details: [
                'Create unlimited question sets!',
                'Access basic Kooblo games',
                'Access to Kooblo Plus Games',
                'Access to all future Kooblo updates'
            ]
        },
        Monthly: {
            name: 'Monthly Plan',
            price: '$6.99/month (Billed Monthly)',
            details: [
                'Create unlimited question sets!',
                'Access basic Kooblo games',
                'Access to Kooblo Plus Games',
                'Access to all future Kooblo updates'
            ]
        }
    };

    const renderPlanCard = (planKey) => {
        const plan = plans[planKey];
        return (
            <Card className="text-center p-4 mb-4">
                <h3 className="titan-one-regular">{plan.name}</h3>
                <p className="p-0 m-0"><span className="titan-one-regular text-bold">{plan.price}</span></p>
                <br />
                {plan.details.map((detail, index) => (
                    <p key={index}>
                        <i className={`fa-solid ${detail.includes('No access') ? 'fa-xmark text-danger' : 'fa-check text-success'}`}></i> {detail}
                    </p>
                ))}
            </Card>
        );
    };

    return (
        <Container>
            <Row>
                <Col xs="12">
                    <h2 className="text-center my-4 titan-one-regular">Update Your Subscription</h2>
                </Col>

                {/* Current Plan */}
                <Col md="4">
                    <h5 className="text-center">Current Plan</h5>
                    {renderPlanCard(currentPlan)}
                </Col>

                {/* Arrow */}
                <Col md="4" className="d-flex align-items-center justify-content-center p-3">
                    <i className="fa-solid fa-arrow-right fa-2x"></i>
                </Col>

                {/* Selected Plan */}
                <Col md="4">
                    <h5 className="text-center">New Plan</h5>
                    {renderPlanCard(selectedPlan)}
                </Col>
            </Row>

            {/* Additional Details */}
            <Row className="mt-4">
                <Col xs="12">
                    <h4>What happens next?</h4>
                    <p>Your new plan starts today.</p>
                    <p>Starting 11/16/24, you’ll be charged NT$198/month + tax.</p>
                    <p>We’ll apply the remainder of this month’s bill to your new plan. Your new billing date is the day that credit runs out.</p>

                    <h4>Payment</h4>
                    <p>Mastercard ending in 7617</p>
                    <p>Expires: 11/2030</p>

                    <p>
                        By purchasing and switching your plan, you authorize us to automatically charge you each month until you cancel. If the price changes, we’ll notify you beforehand. You can check your renewal date or cancel anytime via your Account page. No partial refunds. Terms apply.
                    </p>
                    <p>You agree to only invite people who live at your address.</p>

                    <div className="text-center mt-4">
                        <Button color="primary" onClick={handleUpdateSubscription}>Confirm Update</Button>
                    </div>
                </Col>
            </Row>
        </Container>
    );
};

SubscriptionUpdate.propTypes = {
    selectedPlan: PropTypes.string.isRequired,
    currentPlan: PropTypes.string.isRequired
};

export default SubscriptionUpdate;
