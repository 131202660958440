import { useRef, useState, useEffect} from "react";
import { Input, Container, Row, Col, Card, Label, CardText, 
    Modal,  ModalBody, Button,
    ModalHeader} from 'reactstrap';
import './style.css';
import Dropzone from 'react-dropzone'
import axios from 'axios';
import jeopardyImg from '../../games/jeopardy/jeopardy.png'
import simplequestionImg from '../../assets/images/simplequestion.PNG'
import multipleChoiceImg from '../../assets/images/multiple-choice.PNG'
import { useNavigate } from "react-router-dom";
import GoogleAd from "../GoogleAd/GoogleAd";
const apiUrl = process.env.REACT_APP_API_URL;
const CreateSet = () => {
    const apiKey = process.env.REACT_APP_UNSPLASH_API_KEY;
    const navigate = useNavigate();
    const[title, setTitle] = useState("");
    const[description, setDescription] = useState("");
    const[gameType, setGameType] = useState("");
    const [files, setFiles] = useState([])    
    const [URLModal, setURLModal] = useState(false);
    const [ImgApiModal, setImgApiModal] = useState(false);
    const[previewImage,setPreviewImage]= useState("");
    const[imageURL, setimageURL] = useState("");
    const[imageApiDesc, setimageApiDesc] = useState("");
    const[apiImages, setApiImages] = useState([]);
    const[apiPageCount, setApiPageCount] = useState(1);
    const [loading, setLoading] = useState(false);
    const modalBodyRef = useRef(null);
    const [errors,setErrors] = useState({});
    const toggleURLModal = () => setURLModal(!URLModal);
    const toggleImgApiModal = () => setImgApiModal(!ImgApiModal);

// submit form with data
    const handleFormSubmit = e => {
        e.preventDefault();
        if (!title || title.length <= 3) {
            setErrors({ title: 'Must be more than 3 characters.' });
            return
        } 

        if(!gameType){
            setErrors({ gameType: 'Please select a template.' });
            return
        }
        setErrors({});


        const formData = new FormData();
        if(files[0]instanceof File){
            formData.append("file", files[0]);
        } else { 
            formData.append("file", files);
        }
        formData.append("title", title);
        formData.append("description", description);
        formData.append("gameType", gameType);
        axios.post(`${apiUrl}/createSet`, formData, {
            headers: {
              "Content-Type": "multipart/form-data"
            },
            withCredentials: true
        }).then(res =>{
                if(gameType==='Jeopardy'){
                    navigate('/jeopardy/edit/'+ res.data.id)
                }else if(gameType==='Kooblo'){
                    navigate('/dashboard/kooblo/edit/'+ res.data.id)
                }else if(gameType==='Kooblo Party'){
                    navigate('/dashboard/koobloparty/edit/'+ res.data.id)
                }
        }).catch(err => console.log(err));
        // API.createSet([... formData])
        //     .then(res => 
        //         console.log(res)
        //     )
        //     .catch(err => console.log(err));
      }
  
    

// Title and Description state
    const onTitleChange = e => {
        setTitle(e.target.value);
    }
    const onDescriptionChange = e => {
        setDescription(e.target.value);
    }
// FILEUPLOAD STATE
    const onFileChange = e => {
        if(e.target.files.length !== 0){
            setPreviewImage(URL.createObjectURL(e.target.files[0]))
            setFiles(e.target.files);
        }
      
    }
//  SEARCH BY API STATE

    const onImageApiDescChange = e =>{
        setimageApiDesc(e.target.value)
    }
    const searchImageApi = () => {
        setLoading(true);
        setApiImages([]); // Reset the images array
        setApiPageCount(1); // Reset to the first page
        axios.get(`https://api.unsplash.com/search/photos`, {
            params: {
                query: imageApiDesc,
                page: apiPageCount,
                per_page: 20,
            },
            headers: {
                Authorization: `Client-ID ${apiKey}`
            }
        }).then(res => {
                // Handle the response
                setApiImages(res.data.results);
                setApiPageCount(2);
            })
            .catch(error => {
                console.error('Error fetching images from Unsplash:', error);
            })
            .finally(() => {
                setLoading(false);
            });
      };
        const selectImageAPI = async image => {
            try {
                // Trigger Unsplash download
                await axios.get(image.links.download_location, {
                    headers: {
                        Authorization: `Client-ID ${apiKey}`
                    }
                });
                // Update state
                setImgApiModal(!ImgApiModal);
                setPreviewImage(image.urls.small);
                setFiles(image.urls.small);
        
            } catch (error) {
                console.error("Error triggering image download:", error);
            }
        };
        useEffect(() => {
        if (ImgApiModal) {
          setLoading(false);
        }
      }, [ImgApiModal]);

    useEffect(() => {
        const handleScroll = (event) => {
            const ApiImgBody = event.target;
            if (ApiImgBody.scrollTop + ApiImgBody.clientHeight >= ApiImgBody.scrollHeight - 5 && !loading) {
                setLoading(true);
                fetchImages();
            }
        };

        const ApiImgBody = document.getElementById('ApiImgBody');
        if (ApiImgBody) {
            ApiImgBody.addEventListener('scroll', handleScroll);
            return () => {
                ApiImgBody.removeEventListener('scroll', handleScroll);
            };
        }
      }, [ImgApiModal, loading, ]);
      const fetchImages = () => {
        axios.get(`https://api.unsplash.com/search/photos`, {
            params: {
                query: imageApiDesc,
                page: apiPageCount,
                per_page: 20,
            },
            headers: {
                Authorization: `Client-ID ${apiKey}`
            }
        }).then(res => {
                // Handle the response
                setApiImages(prevImages => [...prevImages, ...res.data.results]);
                setApiPageCount(prevPageCount => prevPageCount + 1);
            })
            .catch(error => {
                console.error('Error fetching images from Unsplash:', error);
            })
            .finally(() => {
                setLoading(false);
            });

    };

// IMAGE URL UPLOAD STATE
    const onURLChange = e =>{
        setimageURL(e.target.value);
    }
    const handleURLSubmit = e => {
        setPreviewImage(imageURL);
        setFiles(imageURL);
        setURLModal(!URLModal);
    }
    //REMOVE PREVIEW IMAGE ALLOW UPLOAD OF A NEW IMAGE
    const removePreviewImage = e =>{
        setPreviewImage("");
        setFiles([]);
    }
    //INVALID IMAGE CALLBACK
    const invalidImage = e =>{
    }
// GAME TEMPLATE STATE
    const setGameTypeClick= (k) =>{
        setGameType(k)
    }

// used to reference button to a file input. using button over input for design purposes
    const fileInput = useRef();
   
  return (

    //    <div className="main-background vertical-center">
        <Container className="h-100">
            <Row className="h-100">
                    <Col xs="12">
                    <GoogleAd 
                        client="ca-pub-3578017559168825"  // Your AdSense Client ID
                        slot="5804698934"  // Your AdSense Slot ID
                        style={{ display: 'block', width: '100%', height: '250px' }}  // Customize size/style
                    />
                    
                    </Col>
                    <Col lg="12"className="align-self-center">
                        <Card>
                            <Container>
                                <Row className="">
                                    <Col lg="6" className="text-center">
                                        
                                        {previewImage ?
                                        <div>
                                            <img src={previewImage}  className="preview-image" onError={()=>invalidImage()}>
                                            </img>
                                            <button onClick={removePreviewImage} className="remove-preview-image">Remove</button>
                                        </div>
                                        
                                        :
                                        <Dropzone multiple={false}noKeyboard={true} noClick={true} onDrop={acceptedFiles => {setPreviewImage(URL.createObjectURL(acceptedFiles[0])); setFiles(acceptedFiles)}}>
                                            {({getRootProps, getInputProps}) => (
                                                <section >
                                                <div {...getRootProps()} className=" titan-one-small dropzone">
                                                    <input accept="image/*" {...getInputProps()} />
                                                        <div > 
                                                            <p>Drag 'n' drop an image here</p>
                                                            <p>or</p>

                                                            {/* use image api to search */}
                                                            <button onClick={toggleImgApiModal} className="btn btn-rounded nav-btn-light-blue navbar-btn">Search an Image</button>

                                                            <Modal size="xl" isOpen={ImgApiModal} toggle={toggleImgApiModal} centered={true} >
                                                                <div className="m-3">
                                                                <Label className="titan-one-small" for="urlText">Search for an image</Label>
                                                                <Input id="urlText" onChange={e=>(onImageApiDescChange(e))} 
                                                                onKeyDown={e => {
                                                                    if (e.key === 'Enter') {
                                                                    searchImageApi();
                                                                    }
                                                                }}/>
                                                                </div>
                                                                <ModalBody id="ApiImgBody" style={{ maxHeight: '800px', overflowY: 'auto' }} ref={modalBodyRef}>
                                                                    <Container>
                                                                    <Row className="text-center justify-content-center align-items-center"> 
                                                                        {apiImages.map(image => (
                                                                            <div key={image.id} className="m-2" style={{ display: 'inline-block', textAlign: 'center', maxWidth: '300px' }}>
                                                                                {/* Image */}
                                                                                <img 
                                                                                    className="pixaImage" 
                                                                                    onClick={() => selectImageAPI(image)} 
                                                                                    src={image.urls.thumb} 
                                                                                    alt={image.alt_description || "Unsplash Image"} 
                                                                                    style={{
                                                                                        cursor: 'pointer', 
                                                                                        borderRadius: '5px', 
                                                                                        maxWidth: '100%', 
                                                                                        maxHeight: '300px'
                                                                                    }} 
                                                                                />
                                                                                {/* Attribution */}
                                                                                <p style={{ fontSize: '10px', marginTop: '8px' }}>
                                                                                    Photo by <a 
                                                                                        href={`${image.user.links.html}${`?utm_source=kooblo&utm_medium=referral`}`} 
                                                                                        target="_blank" 
                                                                                        rel="noopener noreferrer" 
                                                                                        style={{ textDecoration: 'underline', color: '#000' }}>
                                                                                        {image.user.name}
                                                                                    </a> on <a 
                                                                                        href={`https://unsplash.com${`?utm_source=kooblo&utm_medium=referral`}`} 
                                                                                        target="_blank" 
                                                                                        rel="noopener noreferrer" 
                                                                                        style={{ textDecoration: 'underline', color: '#000' }}>
                                                                                        Unsplash
                                                                                    </a>
                                                                                </p>
                                                                            </div>
                                                                        ))}
                                                                    </Row>
                                                                    </Container>

                                                                    {loading && <p>Loading more images...</p>}

                                                                </ModalBody>
                                                                <div className="modal-button">
                                                                <button onClick={searchImageApi } className="btn  btn-rounded nav-btn-light-blue navbar-btn">Search</button>
                                                                <Button color="btn btn-rounded btn-red navbar-btn" onClick={toggleImgApiModal}>
                                                                    Cancel
                                                                </Button>
                                                                </div>
                                                            </Modal>

                                                            {/* upload a local file */}

                                                            <button onClick={()=>fileInput.current.click()} className="btn  btn-rounded nav-btn-light-blue navbar-btn">Upload an Image</button>
                                                            <input ref={fileInput} type="file" accept="image/*"  style={{ display: 'none' }} onChange={onFileChange}/>

                                                            {/* upload by url */}
                                                            <Modal isOpen={URLModal} toggle={toggleURLModal} centered={true} >
                                                                <ModalBody>
                                                                <Label className="titan-one-small" for="urlText">Upload by URL</Label>
                                                                <Input id="urlText" onChange={e=>(onURLChange(e))}/>
                                                                </ModalBody>
                                                                <div className="modal-button">
                                                                <button onClick={handleURLSubmit} className="btn  btn-rounded nav-btn-light-blue navbar-btn">Upload</button>
                                                                <Button color="btn btn-rounded btn-red navbar-btn" onClick={toggleURLModal}>
                                                                    Cancel
                                                                </Button>
                                                                </div>
                                                            </Modal>

                                                            <button onClick={toggleURLModal } className="btn  btn-rounded nav-btn-light-blue navbar-btn">Upload by URL</button>
                                                        </div>
                                                </div>
                                                </section>
                                            )}
                                        </Dropzone>
                                        }



                                    </Col>
                                    <Col lg="6" >
                                        <div className="questionset">
                                        <Label className="titan-one-regular" for="TitleText">Title</Label> <span>(required)   </span> {errors.title && <span style={{ color: 'red', margin: '5px 0' }}>{errors.title}</span>}
                                        <Input type="text" id="TitleText" placeholder = "Title" onChange={e=>(onTitleChange(e)) }/>

                                        <Label className="titan-one-regular" for="DescriptionText">Description</Label>
                                        <Input type="textarea" rows="5" name="text" placeholder = "Description" id="DescriptionText" onChange={e=>(onDescriptionChange(e))}/>
                                        </div>
                                    </Col>
                                </Row>
                                {/* ************************************set template ************************************ */}
                                <Row>
                                    <Col lg="12 ">
                                        <section>
                                            <Container>
                                            <Row className="text-center" >
                                            <Col lg="12">
                                            <h2>Select a template</h2>
                                            </Col>
                                            <Col lg="12">
                                            {errors.gameType && <p style={{ color: 'red', margin: '5px 0' }}>{errors.gameType}</p>}
                                            </Col>

                                            </Row>
                                            <Row className="text-center justify-content-center">
                                            <Col lg="4" sm="6">
                                                    <Card  onClick={()=>setGameTypeClick("Jeopardy")} className={gameType === "Jeopardy" ? "template-selected template-card":"template-card"}  style={{width: '100%'}}>
                                                    <img className="template-image" alt="Sample" src={jeopardyImg}/>
                                                    <h4 className="titan-one-regular">Jeopardy</h4>
                                                    <CardText className="nunito p-3">
                                                        Create questions for multiple categories.
                                                    </CardText>
                                                    </Card>
                                                </Col>
                                                <Col lg="4" sm="6"> 
                                                    <Card  onClick={ ()=>setGameTypeClick("Kooblo")} className={gameType === "Kooblo" ? "template-selected template-card":"template-card"}  style={{width: '100%'}}>
                                                    <img className="template-image" alt="Sample" src={simplequestionImg}/>
                                                    <h4 className="titan-one-regular">Kooblo</h4>
                                                    <CardText className="nunito p-3">
                                                        Create simple questions and answers.
                                                    </CardText>

                                                    </Card>
                                                </Col>

                                                <Col lg="4" sm="6"> 
                                                    <Card  onClick={ ()=>setGameTypeClick("Kooblo Party")} className={gameType === "Kooblo Party" ? "template-selected template-card":"template-card"}  style={{width: '100%'}}>
                                                    <img className="template-image" alt="Sample" src={multipleChoiceImg}/>
                                                    <h4 className="titan-one-regular">Kooblo Party!</h4>
                                                    <CardText className="nunito p-3">
                                                        Create multiple choice questions for party style game play.
                                                    </CardText>

                                                    </Card>
                                                </Col>

                                            </Row>
                                            <Row>
                                                <Col lg="10"></Col>
                                                <Col lg="2"><button onClick={handleFormSubmit} className="btn grow-small btn-rounded nav-btn-light-purple navbar-btn">Create Set</button></Col>
                                            </Row>
                                        </Container>
                 
                                        </section>
                                    </Col>
                                </Row>
                            </Container>
                        </Card>
                    </Col>

            </Row>
        </Container>
    // </div>


  );
};

export default CreateSet; 