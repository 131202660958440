import "./style.css";
import { Card, CardBody, CardImg, CardText, Button, Row, Col, Container, Input} from 'reactstrap';
import ImageUploader from '../ImageUploader';
import axios from "axios";
import React, { useState,useEffect,useRef } from 'react';
import API from "../../utils/API";
import noImage from '../../assets/images/no-image.jpg'
const KoobloPartyQuestion = (props) => {
    // const apiKey = process.env.REACT_APP_PIXABAY_API_KEY;
    const apiKey = process.env.REACT_APP_UNSPLASH_API_KEY;

    const [question,setQuestion] = useState(props.question)
    const [editToggle, setEditToggle] = useState(false);
    const [previewImage, setPreviewImage] = useState(null);
    const [files, setFiles] = useState([]);
    const [ImgApiModal, setImgApiModal] = useState(false);
    const [apiImages, setApiImages] = useState([]);
    const [URLModal, setURLModal] = useState(false);
    const [imageApiDesc, setimageApiDesc] = useState("");
    const [apiPageCount, setApiPageCount] = useState(1);
    const [imageURL, setimageURL] = useState("");
    const [questionEdit, setQuestionEdit] = useState('');
    const [answerEdit, setAnswerEdit] = useState('');
    const [deleteToggle, setDeleteToggle] = useState(false);
    const [loading, setLoading] = useState(false);
    const modalBodyRef = useRef(null);

    const [answers, setAnswers] = useState({
        answer1: '',
        answer2: '',
        answer3: '',
        answer4: '',
      });
    const [correctAnswers, setCorrectAnswers] = useState({
        answer1: false,
        answer2: false,
        answer3: false,
        answer4: false,
    });

    const fileInput = useRef(null);

    const toggleImgApiModal = () => setImgApiModal(!ImgApiModal);
    const toggleURLModal = () => setURLModal(!URLModal);
    
    useEffect(()=>{
        if(question.multiAnswers){
            let answersTemp = {...answers};
            answersTemp.answer1 = question.multiAnswers.answer1.answer;
            answersTemp.answer2 = question.multiAnswers.answer2.answer;
            answersTemp.answer3 = question.multiAnswers.answer3.answer;
            answersTemp.answer4 = question.multiAnswers.answer4.answer;
            setAnswers(answersTemp);
            let correctAnswersTemp  = {...correctAnswers};
            correctAnswersTemp.answer1 = question.multiAnswers.answer1.correctAnswer === 'true';
            correctAnswersTemp.answer2 = question.multiAnswers.answer2.correctAnswer === 'true';
            correctAnswersTemp.answer3 = question.multiAnswers.answer3.correctAnswer === 'true';
            correctAnswersTemp.answer4 = question.multiAnswers.answer4.correctAnswer === 'true';
            setCorrectAnswers(correctAnswersTemp);    
        }
    },[editToggle])
    const onImageApiDescChange = e =>{
        setimageApiDesc(e.target.value)
        setApiPageCount(1);

    }
    const selectImageAPI = async image => {
        try {
            // Trigger Unsplash download
            await axios.get(image.links.download_location, {
                headers: {
                    Authorization: `Client-ID ${apiKey}`
                }
            });
            // Update state
            setImgApiModal(!ImgApiModal);
            setPreviewImage(image.urls.small);
            setFiles(image.urls.small);
    
        } catch (error) {
            console.error("Error triggering image download:", error);
        }
    };

    const searchImageApi = () => {
        setLoading(true);
        setApiImages([]); // Reset the images array
        setApiPageCount(1); // Reset to the first page
        axios.get(`https://api.unsplash.com/search/photos`, {
            params: {
                query: imageApiDesc,
                page: apiPageCount,
                per_page: 20,
            },
            headers: {
                Authorization: `Client-ID ${apiKey}`
            }
        }).then(res => {
                // Handle the response
                console.log(res.data)
                setApiImages(res.data.results);
                setApiPageCount(2);
            })
            .catch(error => {
                console.error('Error fetching images from Unsplash:', error);
            })
            .finally(() => {
                setLoading(false);
            });

      };
      useEffect(() => {
        if (ImgApiModal) {
          setLoading(false);
        }
      }, [ImgApiModal]);
      
      useEffect(() => {
        const handleScroll = (event) => {
            const ApiImgBody = event.target;
            if (ApiImgBody.scrollTop + ApiImgBody.clientHeight >= ApiImgBody.scrollHeight - 5 && !loading) {
                console.log(apiPageCount)
                setLoading(true);
                fetchImages();
            }
        };

        const ApiImgBody = document.getElementById('ApiImgBody');
        if (ApiImgBody) {
            ApiImgBody.addEventListener('scroll', handleScroll);
            return () => {
                ApiImgBody.removeEventListener('scroll', handleScroll);
            };
        }
      }, [ImgApiModal, loading, ]);

      const fetchImages = () => {
        // axios.get(`https://pixabay.com/api/?key=${apiKey}&q=${imageApiDesc}&image_type=photo&per_page=20&page=${apiPageCount}`)
        //     .then(res => {
        //         setApiImages(prevImages => [...prevImages, ...res.data.hits]);
        //         setApiPageCount(prevPageCount => prevPageCount + 1);
        //     })
        //     .catch(error => {
        //         console.error('Error fetching images:', error);
        //     })
        //     .finally(() => {
        //         setLoading(false);
        //     });
        axios.get(`https://api.unsplash.com/search/photos`, {
            params: {
                query: imageApiDesc,
                page: apiPageCount,
                per_page: 20,
            },
            headers: {
                Authorization: `Client-ID ${apiKey}`
            }
        }).then(res => {
                // Handle the response
                setApiImages(prevImages => [...prevImages, ...res.data.results]);
                setApiPageCount(prevPageCount => prevPageCount + 1);
            })
            .catch(error => {
                console.error('Error fetching images from Unsplash:', error);
            })
            .finally(() => {
                setLoading(false);
            });
        
    };
    // end api image search

    const onFileChange = e => {
        if(e.target.files.length !== 0){
            setPreviewImage(URL.createObjectURL(e.target.files[0]))
            setFiles(e.target.files);
        }
    }
    const onURLChange = e =>{
        setimageURL(e.target.value);
    }
    const handleURLSubmit = e => {
        setPreviewImage(imageURL);
        setFiles(imageURL);
        setURLModal(!URLModal);
    }
  
    const onEditToggleClick = ()=>{
      setEditToggle(!editToggle);
      setQuestionEdit(question.question);
      setAnswerEdit(question.answer);
      if(question.image){
        setFiles(question.image);
        setPreviewImage(question.image);
      }else{
        setFiles(null);
        setPreviewImage(null);
      }
    }
    //INVALID IMAGE CALLBACK
    const invalidImage = e =>{
    }

    const onQuestionChange = e =>{
        setQuestionEdit(e.target.value);
    }
    // const onAnswerChange = e =>{
    //     setAnswerEdit(e.target.value);
    // }
    const handleAnswerChange = (e, key) => {
        setAnswers({
          ...answers,
          [key]: e.target.value,
        });
      };
      // Function to handle checkbox change
    
      const handleCheckboxChange = (key) => {
        setCorrectAnswers(prevState => ({
          ...prevState,
          [key]: !prevState[key],
        }));
      };
    
    const onSaveClick = ()=>{
        let data = {...question}
            data.question = questionEdit;
            data.files=files;
        let multiAnswersTemp = {};
        Object.keys(answers).forEach(function(key, index) {
            multiAnswersTemp[key] = {
                answer: answers[key],
                correctAnswer: correctAnswers[key] || false, // Set default value if correctAnswers[key] is undefined
              };
            });
        data.multiAnswers=multiAnswersTemp;

        API.editQuestion(data).then(res=>{
            // const updatedQuestions = [...questions, newQuestion];
            // setQuestions(updatedQuestions);
            setQuestion(res.data[0]);
            setEditToggle(!editToggle);
            }).catch(err=>{
            console.log(err);
          })
    
    }
    const onCancelClick =()=>{
        setEditToggle(!editToggle);
    }
    const onDeleteToggle =()=>{
        setDeleteToggle(true);
    }
    //REMOVE PREVIEW IMAGE ALLOW UPLOAD OF A NEW IMAGE
    const removePreviewImage = e =>{
        setPreviewImage("");
        setFiles(null);
    }
    //~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~handle text area focus~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
    const textareaRef1 = useRef(null);
    const textareaRef2 = useRef(null);
    const textareaRef3 = useRef(null);
    const textareaRef4 = useRef(null);
    const textAreaClicked = (ref) => {
        if (ref.current) {
          ref.current.focus();
        }
    };
      // Function to adjust textarea height
    const adjustTextareaHeight = (ref) => {
        if (ref.current) {
        ref.current.style.height = 'auto';
        ref.current.style.height = `${Math.min(ref.current.scrollHeight, 200)}px`; // Set max height to 200px
    }
    };

    // Use effect to adjust height on initial render and whenever the content changes
    useEffect(() => {
        [textareaRef1, textareaRef2, textareaRef3, textareaRef4].forEach(ref => adjustTextareaHeight(ref));
    }, [answerEdit]);


    //~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~handle text area focus end ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~

  return (
    <Card className="kq-question-card">
        {deleteToggle?
        // <Row noGutters className="h-100 min-height-200 m-0">
        <div >

            <div className='h-100 align-content-center text-center min-height-200'>
            <i class="fa-solid fa-trash-can"></i>
            <p className="titan-one-regular p-0 m-0" >Are you sure?</p>
            </div>
            <div className="class-link-group nunito">
                <p className="class-card-custom-link class-card-custom-link-delete" onClick={props.onDeleteClick}>Yes</p>
                <p className="class-card-custom-link " onClick={()=>setDeleteToggle(false)}>No</p>
            </div>
        </div>

        // </Row>
        :

        <Row noGutters className="h-100 min-height-200 m-0">
            
            <Col md="4" className="image-col kq-col-4 d-flex align-items-center p-0">
                {editToggle?
                    (      previewImage?
                        <div>
                        <img src={previewImage} className="preview-image m-0 p-0"  onError={()=>invalidImage()}></img>
                        <button onClick={removePreviewImage} className="remove-preview-image">Remove</button>
                        </div>
            
                    :      <ImageUploader
                            setPreviewImage={setPreviewImage}
                            setFiles={setFiles}
                            toggleImgApiModal={toggleImgApiModal}
                            ImgApiModal={ImgApiModal}
                            onImageApiDescChange={onImageApiDescChange}
                            apiImages={apiImages}
                            setApiImages={setApiImages}
                            selectImageAPI={selectImageAPI}
                            searchImageApi={searchImageApi}
                            fileInput={fileInput}
                            onFileChange={onFileChange}
                            URLModal={URLModal}
                            toggleURLModal={toggleURLModal}
                            onURLChange={onURLChange}
                            handleURLSubmit={handleURLSubmit}
                            apiPageCount={apiPageCount}
                            setApiPageCount={setApiPageCount}
                            loading={loading}
                            modalBodyRef={modalBodyRef}
                        />
                )
                :
                <CardImg src={question.image ? question.image:noImage} alt="Question Image" className="kp-questions-image" />

                }

            </Col>
            <Col md="8" className="d-flex flex-column kq-col-8 p-0">
                <CardBody className="d-flex flex-column justify-content-between p-0">
                        {editToggle ?
                            <Container className=""fluid> 
                                <Row>
                                    <Col md="12">
                                    <CardText className="text-center"><strong>Question:</strong> </CardText>
                                    <Input type="textarea"  rows="2" id="questionText"  placeholder = "Question" defaultValue={questionEdit} onChange={e=>onQuestionChange(e)}/>
                                    </Col>
                                </Row>
                                <CardText className="text-center"><strong>Answer:</strong> {props.answer}</CardText>
                                <Row className="">
                                    <Col md="6" className="answer-area1 answer-area p-0">
                                    <div 
                                        className={`checkbox-container custom-checkbox ${correctAnswers.answer1 ? 'checked' : ''}`} 
                                        onClick={() => handleCheckboxChange('answer1')}
                                        >
                                        <i className="fa-solid fa-check"></i>
                                    </div>
                                    <div className="text-area-container" onClick={() => textAreaClicked(textareaRef1)}>
                                        <textarea spellcheck="false" type="textarea" rows="1" name="text" placeholder="Answer" id="answerText1" ref={textareaRef1} defaultValue={answers.answer1} onChange={(e) => { handleAnswerChange(e, 'answer1'); adjustTextareaHeight(textareaRef1); }}/>
                                    </div>
                                    </Col>
                                    <Col md="6" className="answer-area2 answer-area p-0">
                                    <div 
                                        className={`checkbox-container custom-checkbox ${correctAnswers.answer2 ? 'checked' : ''}`} 
                                        onClick={() => handleCheckboxChange('answer2')}
                                        >
                                        <i className="fa-solid fa-check"></i>
                                    </div>
                                    <div className="text-area-container" onClick={() => textAreaClicked(textareaRef2)}>
                                        <textarea spellcheck="false" type="textarea" rows="1" name="text" placeholder="Answer" id="answerText2" ref={textareaRef2} defaultValue={answers.answer2} onChange={(e) => { handleAnswerChange(e, 'answer2');  adjustTextareaHeight(textareaRef2); }}/>
                                    </div>
                                    </Col>
                                    <Col md="6" className="answer-area3 answer-area p-0">
                                    <div 
                                        className={`checkbox-container custom-checkbox ${correctAnswers.answer3 ? 'checked' : ''}`} 
                                        onClick={() => handleCheckboxChange('answer3')}
                                        >
                                        <i className="fa-solid fa-check"></i>
                                    </div>
                                    <div className="text-area-container" onClick={() => textAreaClicked(textareaRef3)}>
                                        <textarea spellcheck="false" type="textarea" rows="1" name="text" placeholder="Answer" id="answerText3" ref={textareaRef3} defaultValue={answers.answer3} onChange={(e) => { handleAnswerChange(e, 'answer3');  adjustTextareaHeight(textareaRef3); }}/>
                                    </div>
                                    </Col>
                                    <Col md="6" className="answer-area4 answer-area p-0">
                                    <div 
                                        className={`checkbox-container custom-checkbox ${correctAnswers.answer4 ? 'checked' : ''}`} 
                                        onClick={() => handleCheckboxChange('answer4')}
                                        >
                                        <i className="fa-solid fa-check"></i>
                                    </div>

                                    <div className="text-area-container" onClick={() => textAreaClicked(textareaRef4)}>
                                        <textarea spellcheck="false" type="textarea" rows="1" name="text" placeholder="Answer" id="answerText4" ref={textareaRef4} defaultValue={answers.answer4} onChange={(e) => { handleAnswerChange(e, 'answer4'); adjustTextareaHeight(textareaRef4); }}/>
                                    </div>
                                    </Col>
                                </Row>

                            </Container>

                            :
                            <Container className="h-100 d-flex flex-column justify-content-between">
                                <Row className="text-center">
                                    <Col md="12">
                                    <CardText className="text-center"><strong>Question:</strong> </CardText>
                                    <p >
                                        {question.question}
                                    </p>
                                    </Col>
                                </Row>
                                <Row className="">
                                    <Col md="6" className="answer-area1 answer-area p-0">
                                    <div className={'align-items-center'} >
                                        {correctAnswers.answer1? 
                                            <i className="fa-solid fa-check"></i>
                                            :
                                            <i className="fa-solid fa-times"></i>
                                        }
                                    </div>
                                    <div className="kp-answer-container">
                                        <p className="m-0">{answers.answer1}</p>
                                    </div>
                                    </Col>
                                    <Col md="6" className="answer-area2 answer-area p-0">
                                    <div className={''} >
                                        {correctAnswers.answer2? 
                                            <i className="fa-solid fa-check"></i>
                                            :
                                            <i className="fa-solid fa-times"></i>
                                        }
                                    </div>
                                    <div className="kp-answer-container">
                                        <p className="m-0">{answers.answer2}</p>
                                    </div>
                                    </Col>
                                    <Col md="6" className="answer-area3 answer-area p-0">
                                    <div className={''} >
                                        {correctAnswers.answer3? 
                                            <i className="fa-solid fa-check"></i>
                                            :
                                            <i className="fa-solid fa-times"></i>
                                        }
                                    </div>
                                    <div className="kp-answer-container">
                                        <p className="m-0">{answers.answer3}</p>
                                    </div>
                                    </Col>
                                    <Col md="6" className="answer-area4 answer-area p-0">
                                    <div className={''} >
                                        {correctAnswers.answer4? 
                                            <i className="fa-solid fa-check"></i>
                                            :
                                            <i className="fa-solid fa-times"></i>
                                        }
                                    </div>
                                    <div className="kp-answer-container">
                                        <p className="m-0">{answers.answer4}</p>
                                    </div>
                                    </Col>

                                </Row>
                            </Container>

                        }
                    {editToggle?
                        <div className="kq-link-group nunito text-center">
                            <p className="kq-card-custom-link text-center player-card-add-button" onClick={onSaveClick}>Save</p>
                            <p className="kq-card-custom-link text-center player-card-minus-button" onClick={onCancelClick}>Cancel</p>                  
                        </div>
                    :
                        <div className="kq-link-group nunito text-center">
                            <p className="kq-card-custom-link text-center player-card-add-button" onClick={onEditToggleClick}>Edit</p>
                            <p className="kq-card-custom-link text-center player-card-minus-button" onClick={onDeleteToggle}>Delete</p>                  
                        </div>

                    }
                </CardBody>
            </Col>
        </Row>
    }
</Card>

);
};


export default KoobloPartyQuestion;