import React, { useEffect } from 'react';

const GoogleAd = ({ client, slot, style }) => {
    useEffect(() => {
        // Ensure that the adsbygoogle script is loaded only once
        const script = document.createElement("script");
        script.async = true;
        script.src = "https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js";
        script.setAttribute("crossorigin", "anonymous");
        document.body.appendChild(script);

        script.onload = () => {
            (window.adsbygoogle = window.adsbygoogle || []).push({});
        };

        return () => {
            // Clean up if needed when the component is removed
            document.body.removeChild(script);
        };
    }, []);

    return (
        <ins
            className="adsbygoogle"
            style={style}
            data-ad-client={client}
            data-ad-slot={slot}
            data-ad-format="auto"
            data-full-width-responsive="true"
        ></ins>
    );
};

export default GoogleAd;
