// SubscriptionCancel.js
import React from 'react';
import { Container, Row, Col, Card, Button } from 'reactstrap';
import axios from 'axios';

const apiUrl = process.env.REACT_APP_API_URL;

const SubscriptionCancel = ({ currentPlan, onCancelSuccess, onGoBack }) => {
    // Function to handle subscription cancellation
    const handleCancelSubscription = async () => {
        try {
            const response = await axios.post(`${apiUrl}/cancel-subscription`, {}, { withCredentials: true });
            
            if (response.data.success) {
                // Call the callback function to notify parent about successful cancellation
                onCancelSuccess(response.data.updatedUser);
            } else {
                throw new Error(response.data.message);
            }
        } catch (error) {
            console.error('Error canceling subscription:', error);
        }
    };

    return (
        <Container className="subscription-cancel nunito h-100 d-flex align-items-center justify-content-center" fluid>
            <Row>
                <Col xs="12">
                    <h2 className="text-center my-4 titan-one-regular">Cancel Subscription</h2>
                </Col>
                
                {/* Display Current Plan */}
                <Col md="6" className="offset-md-3">
                    <Card className="text-center p-4 mb-4">
                        <h3 className='titan-one-regular'>{currentPlan} Plan</h3>
                        <p className='p-0 m-0'>
                            {currentPlan === 'Free' ? (
                                <span className='titan-one-regular text-bold'>$0</span>
                            ) : currentPlan === 'Monthly' ? (
                                <span className='titan-one-regular text-bold'>$6.99</span>
                            ) : (
                                <span className='titan-one-regular text-bold'>$4.99</span>
                            )} /month
                        </p>
                        
                        {/* Show confirmation message */}
                        <p className="mt-4">
                            Are you sure you want to cancel your {currentPlan} subscription? This will end your access to premium features.
                        </p>
                        
                        {/* Cancel Confirmation and Go Back Buttons */}
                        <div className="d-flex justify-content-center mt-3">
                            <Button color="secondary" onClick={onGoBack} style={{ marginRight: '10px' }}>
                                Go Back
                            </Button>

                            <Button color="danger" onClick={handleCancelSubscription} className="me-3">
                                Confirm Cancellation
                            </Button>
                        </div>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
};

export default SubscriptionCancel;
