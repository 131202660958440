import React, { useContext, useState, useEffect } from 'react';
import { Container, Row, Col, Card, Button } from 'reactstrap';
import { AuthContext } from '../AuthContext/AuthContext';
import SubscriptionUpdate from './SubscriptionUpdate';
import SubscriptionCancel from './SubscriptionCancel'; // Import SubscriptionCancel component
import axios from 'axios';
import './style.css';

const apiUrl = process.env.REACT_APP_API_URL;

const SubscriptionManager = () => {
    const { user, setUser } = useContext(AuthContext);
    const [selectedPlan, setSelectedPlan] = useState(null);
    const [isSubscriptionUpdateVisible, setIsSubscriptionUpdateVisible] = useState(false);
    const [isSubscriptionCancelVisible, setIsSubscriptionCancelVisible] = useState(false);

    const handlePlanSelection = async (plan) => {
        const priceIdMap = {
            Annual: process.env.NODE_ENV === 'production'
                ? 'price_1QNemeDwwfybDM0zzCo3xdu5' // Live Price ID for Annual
                : 'price_1Q9tZKDwwfybDM0zxojIduE5', // Test Price ID for Annual
            Monthly: process.env.NODE_ENV === 'production'
                ? 'price_1QNelDDwwfybDM0zOAlaupwK' // Live Price ID for Monthly
                : 'price_1Q9syyDwwfybDM0zlTeuTubw', // Test Price ID for Monthly
        };
        
        const selectedPriceId = priceIdMap[plan];

        try {
            const response = await axios.post(`${apiUrl}/create-checkout-session`, {
                planId: selectedPriceId,
                planType: plan,
            }, { withCredentials: true });

            window.location.href = response.data.url;
        } catch (error) {
            console.error('Error creating checkout session:', error);
        }
    };

    const handleUpdateSubscription = (plan) => {
        setSelectedPlan(plan);
        setIsSubscriptionUpdateVisible(true);
    };

    const handleCancelPlan = () => {
        setIsSubscriptionCancelVisible(true); // Show SubscriptionCancel component
    };

    const handleCancelSuccess = (updatedUser) => {
        setUser(updatedUser); // Update user data
        setIsSubscriptionCancelVisible(false); // Close cancel view
    };

    const handleGoBack = () => {
        setIsSubscriptionCancelVisible(false); // Return to main view without canceling
    };
    
    const isUserLoaded = user !== null;
    const handleUncancelSubscription = async () => {
        try {
            // Make an API call to uncancel the subscription
            const response = await axios.post(`${apiUrl}/uncancel-subscription`, {}, { withCredentials: true });
            
            // Update user state based on response
            setUser(response.data.updatedUser);
        } catch (error) {
            console.error('Error uncanceling subscription:', error);
        }
    };    
    const subscriptionMessage = () => {
        if (!user) return ''; // Return an empty string if user is null
    
        // Message for active subscriptions with a renewal date
        if (user.subscriptionStatus === 'active' && user.nextRenewalDate) {
            return `Your plan will renew on ${new Date(user.nextRenewalDate).toLocaleDateString()}.`;
        } 
        
        // Message for canceled subscriptions with a cancellation date and an uncancel link
        else if (user.subscriptionStatus === 'canceled' && user.nextRenewalDate) {
            return (
                <>
                    Your plan will be canceled on {new Date(user.nextRenewalDate).toLocaleDateString()}.{' '}
                    <a href="#" onClick={(e) => { e.preventDefault(); handleUncancelSubscription(); }}>
                        Click here to uncancel
                    </a>
                </>
            );
        } 
        
        // Message for subscriptions canceled due to payment failure
        else if (user.cancellationReason === 'payment_failure') {
            return (
                <>
                    There was an issue with your recent payment, and your subscription has been canceled.
                </>
            );
        } else {
            return ''; // Show nothing if no special conditions apply
        }
    };
                return (
        <Container className="subscription-manager nunito h-100 d-flex align-items-center justify-content-center" fluid>
            {!isSubscriptionUpdateVisible && !isSubscriptionCancelVisible ? (
                <Row>
                    <Col xs="12">
                        <h2 className="text-center my-4 titan-one-regular">Choose Your Kooblo Plan</h2>
                    </Col>

                    <Col md="4">
                        <Card className="text-center p-4 mb-4 free-plan">
                            <h3 className='titan-one-regular'>Free Plan</h3>
                            <p className='p-0 m-0'><span className='titan-one-regular text-bold'>$0</span>/month</p>
                            <small className="placeholder-text">&nbsp;</small> {/* Placeholder for alignment */}
                            <br />
                            <p><i className="fa-solid fa-check text-success"></i> Create up to 10 question sets </p>
                            <p><i className="fa-solid fa-check text-success"></i> Access basic Kooblo games </p>
                            <p><i className="fa-solid fa-xmark text-danger"></i> Access to Kooblo Plus Games!</p>
                            <p><i className="fa-solid fa-xmark text-danger"></i> Access to all future Kooblo updates!</p>

                            {isUserLoaded && user.planType === null ? (
                                <Button color="secondary" disabled>
                                    Current
                                </Button>
                            ) : (
                                isUserLoaded && (
                                    <Button color="primary" onClick={handleCancelPlan} disabled={user.subscriptionStatus !== 'active'}>
                                        Cancel Plan
                                    </Button>
                                )
                            )}
                        </Card>
                    </Col>

                    <Col md="4">
                        <Card className="text-center p-4 mb-4 yearly-plan">
                            <h3 className='titan-one-regular'>Yearly Plan</h3>
                            <p className='p-0 m-0'><span className='titan-one-regular text-bold'>$4.99</span>/month</p>
                            <small>$59.88 Billed Annually</small>
                            <br />
                            <p><i className="fa-solid fa-check text-success"></i> Create unlimited question sets!</p>
                            <p><i className="fa-solid fa-check text-success"></i> Access basic Kooblo games </p>
                            <p><i className="fa-solid fa-check text-success"></i> Access to Kooblo Plus Games!</p>
                            <p><i className="fa-solid fa-check text-success"></i> Access to all future Kooblo updates!</p>

                            {isUserLoaded && user.planType === 'Annual' ? (
                                <Button color="secondary" disabled>
                                    Current
                                </Button>
                            ) : (
                                <Button color="primary" onClick={() => isUserLoaded && user.subscriptionStatus === 'active' ? handleUpdateSubscription('Annual') : handlePlanSelection('Annual')} disabled={isUserLoaded && user.subscriptionStatus === 'canceled'}>
                                    {isUserLoaded && user.subscriptionStatus === 'active' ? 'Change Plan' : 'Choose Yearly Plan'}
                                </Button>
                            )}
                        </Card>
                    </Col>

                    <Col md="4">
                        <Card className="text-center p-4 mb-4 monthly-plan">
                            <h3 className='titan-one-regular'>Monthly Plan</h3>
                            <p className='p-0 m-0'><span className='titan-one-regular text-bold'>$6.99</span>/month</p>
                            <small>Billed Monthly</small>
                            <br />
                            <p><i className="fa-solid fa-check text-success"></i> Create unlimited question sets!</p>
                            <p><i className="fa-solid fa-check text-success"></i> Access basic Kooblo games </p>
                            <p><i className="fa-solid fa-check text-success"></i> Access to Kooblo Plus Games!</p>
                            <p><i className="fa-solid fa-check text-success"></i> Access to all future Kooblo updates!</p>

                            {isUserLoaded && user.planType === 'Monthly' ? (
                                <Button color="secondary" disabled>
                                    Current
                                </Button>
                            ) : (
                                <Button color="primary" onClick={() => isUserLoaded && user.subscriptionStatus === 'active' ? handleUpdateSubscription('Monthly') : handlePlanSelection('Monthly')}disabled={isUserLoaded && user.subscriptionStatus === 'canceled'}>
                                    {isUserLoaded && user.subscriptionStatus === 'active' ? 'Change Plan' : 'Choose Monthly Plan'}
                                </Button>
                            )}
                        </Card>
                    </Col>

                    <Col xs="12" className="text-center mt-3">
                        <p>{subscriptionMessage()}</p>
                    </Col>

                </Row>
            ) : isSubscriptionUpdateVisible ? (
                <SubscriptionUpdate
                    selectedPlan={selectedPlan}
                    currentPlan={user.planType}
                />
            ) : (
                <SubscriptionCancel
                    currentPlan={user.planType}
                    onCancelSuccess={handleCancelSuccess}
                    onGoBack={handleGoBack} // Pass the go-back function
                />
            )}
        </Container>
    );
};

export default SubscriptionManager;
