import React, { useEffect, useState,useContext} from 'react';
import axios from 'axios';
import { Container, Row, Col, Card, Button, Spinner } from 'reactstrap';
import { useNavigate} from 'react-router-dom';
import { AuthContext } from '../AuthContext/AuthContext';
const SubscriptionSuccess = () => {
    const [subscriptionData, setSubscriptionData] = useState(null);
    const { user } = useContext(AuthContext);
    const [loading, setLoading] = useState(false);
    const sessionId = new URLSearchParams(window.location.search).get('session_id');
    const navigate = useNavigate();

    // useEffect(() => {
    //     const fetchSubscription = async () => {
    //         try {
    //             const response = await axios.get(`/checkout-success?session_id=${sessionId}`, { withCredentials: true });
    //             setSubscriptionData(response.data);
    //         } catch (error) {
    //             console.error('Error fetching subscription data:', error);
    //         } finally {
    //             setLoading(false);
    //         }
    //     };

    //     if (sessionId) {
    //         fetchSubscription();
    //     }
    // }, [sessionId]);

    const handleGoToDashboard = () => {
        navigate('/dashboard');
    };
    const formatDate = (dateString) => {
        if (!dateString) return 'N/A';
        const date = new Date(dateString);
        return new Intl.DateTimeFormat('en-US', {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
        }).format(date);
    };
    

    return (
        <Container className="d-flex align-items-center justify-content-center min-vh-100">
            <Card body className="p-4 text-center shadow" style={{ maxWidth: '600px', width: '100%' }}>
                <h1 className="mb-4 titan-one-regular">Subscription Successful</h1>
                {loading ? (
                    <Spinner color="primary" />
                ) : user ? (
                    <div className='nunito'>
                        <p>Thank you for subscribing to Kooblo Plus!</p>
                        <h3 className="mt-4">Your Subscription Details:</h3>
                        <ul className="list-unstyled">
                            <li><strong>Plan:</strong> {user.planType}</li>
                            {/* <li><strong>Price:</strong> {subscriptionData.price}</li> */}
                            <li><strong>Renewal Date:</strong> {formatDate(user.nextRenewalDate)}</li>
                        </ul>

                        <Button color="primary" className="mt-3" onClick={handleGoToDashboard}>
                            Go to Dashboard
                        </Button>
                    </div>
                ) : (
                    <p>Unable to load subscription details.</p>
                )}
            </Card>
        </Container>
    );
};

export default SubscriptionSuccess;
