import React from 'react';
import {  Container, Row, Col, Card } from 'reactstrap';

const TermsOfService = () => {
  return (
    <div className="TermsOfService main-background nunito">
        <Container className='p-3'>
            <Card className='p-3'>
            <h1>Terms of Service</h1>
            <p>Last Updated: 11/21/2024</p>

            <h2>1. Introduction</h2>
            <p>Welcome to Kooblo ("we," "our," or "us"). By accessing or using our platform, you agree to these Terms of Service ("Terms"). If you do not agree, please do not use Kooblo.</p>

            <h2>2. Description of Service</h2>
            <p>Kooblo is an EdTech platform where users can create custom question sets and play games using these sets. We offer both free and paid features, as detailed below.</p>

            <h2>3. User Eligibility</h2>
            <p>Kooblo is open to users of all ages. However, users under the age of 13 must have parental or guardian consent to create an account.</p>

            <h2>4. Account Registration</h2>
            <p>To use certain features of Kooblo, you must register for an account and provide a valid email address. You are responsible for maintaining the confidentiality of your login credentials.</p>

            <h2>5. Payment Terms</h2>
            <p>Kooblo offers both free and paid subscription plans. Paid plans are available at $6.99 per month or $4.99 per month billed annually. By subscribing, you authorize us to charge the payment method provided.</p>

            <h2>6. Content Ownership</h2>
            <p>Users own the custom question sets they create on Kooblo. However, by creating or uploading content, you grant us a non-exclusive license to use, modify, and display the content as part of our platform. Kooblo retains ownership of all games and related intellectual property created for the platform.</p>

            <h2>7. Prohibited Activities</h2>
            <p>Users may not engage in the following activities on Kooblo:</p>
            <ul>
                <li>Uploading or sharing inappropriate, offensive, or illegal content.</li>
                <li>Copying, reproducing, or reverse-engineering Kooblo's website or games without authorization.</li>
                <li>Violating the intellectual property rights of others.</li>
            </ul>

            <h2>8. Termination</h2>
            <p>We reserve the right to suspend or terminate your account if you violate these Terms or engage in prohibited activities. Termination may occur with or without notice, at our sole discretion.</p>

            <h2>9. Limitation of Liability</h2>
            <p>Kooblo is provided "as is" and "as available." We do not guarantee uninterrupted access to our services. We are not responsible for any user-generated content or third-party services accessed through our platform.</p>

            <h2>10. Governing Law</h2>
            <p>These Terms are governed by the laws of the State of California, USA, without regard to its conflict of laws principles.</p>

            <h2>11. Contact Us</h2>
            <p>If you have questions or concerns about these Terms, please contact us at <a href="mailto:support@kooblo.io">support@kooblo.io</a>.</p>

            <h2>12. Changes to Terms</h2>
            <p>We may update these Terms from time to time. Any changes will be posted on this page, and your continued use of Kooblo constitutes acceptance of the updated Terms.</p>
 
            </Card>
        </Container>

    </div>
  );
};

export default TermsOfService;
