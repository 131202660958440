import React from 'react';
import Dropzone from 'react-dropzone';
import {
  Modal,
  ModalBody,
  Button,
  Label,
  Input,
  Container,
  Row
} from 'reactstrap';

const ImageUploader = ({
  setPreviewImage,
  setFiles,
  toggleImgApiModal,
  ImgApiModal,
  onImageApiDescChange,
  apiImages,
  setApiImages,
  selectImageAPI,
  searchImageApi,
  fileInput,
  onFileChange,
  URLModal,
  toggleURLModal,
  onURLChange,
  handleURLSubmit,
  apiPageCount,
  setApiPageCount,
  loading,
  modalBodyRef,

}) => {
  return (
    <Dropzone multiple={false} noKeyboard={true} noClick={true} onDrop={acceptedFiles => {
      setPreviewImage(URL.createObjectURL(acceptedFiles[0]));
      setFiles(acceptedFiles);
    }}>
      {({ getRootProps, getInputProps }) => (
        <section>
          <div {...getRootProps()} className="titan-one-regular dropzone">
            <input accept="image/*" {...getInputProps()} />
            <div>
              <p>Drag 'n' drop an image here</p>
              <p>or</p>


              {/* use image api to search */}
              <button onClick={toggleImgApiModal} className="btn btn-rounded nav-btn-light-blue navbar-btn">Search an Image</button>

              <Modal size="xl" isOpen={ImgApiModal} toggle={toggleImgApiModal} centered={true} >
                  <div className="m-3">
                  <Label className="titan-one-small" for="urlText">Search for an image</Label>
                  <Input id="urlText" onChange={e=>(onImageApiDescChange(e))} 
                  onKeyDown={e => {
                      if (e.key === 'Enter') {
                      searchImageApi();
                      }
                  }}/>
                  </div>
                  <ModalBody id="ApiImgBody" style={{ maxHeight: '800px', overflowY: 'auto' }} ref={modalBodyRef}>
                      <Container>
                          <Row className="text-center justify-content-center align-items-center"> 
                              {apiImages.map(image=>(
                                <div key={image.id} className="m-2" style={{ display: 'inline-block', textAlign: 'center', maxWidth: '300px' }}>
                                {/* Image */}
                                <img 
                                    className="pixaImage" 
                                    onClick={() => selectImageAPI(image)} 
                                    src={image.urls.thumb} 
                                    alt={image.alt_description || "Unsplash Image"} 
                                    style={{
                                        cursor: 'pointer', 
                                        borderRadius: '5px', 
                                        maxWidth: '100%', 
                                        maxHeight: '300px'
                                    }} 
                                />
                              {/* Attribution */}
                              <p style={{ fontSize: '10px', marginTop: '8px' }}>
                                  Photo by <a 
                                      href={`${image.user.links.html}${`?utm_source=kooblo&utm_medium=referral`}`} 
                                      target="_blank" 
                                      rel="noopener noreferrer" 
                                      style={{ textDecoration: 'underline', color: '#000' }}>
                                      {image.user.name}
                                  </a> on <a 
                                      href={`https://unsplash.com${`?utm_source=kooblo&utm_medium=referral`}`} 
                                      target="_blank" 
                                      rel="noopener noreferrer" 
                                      style={{ textDecoration: 'underline', color: '#000' }}>
                                      Unsplash
                                  </a>
                              </p>
                            </div>
                          ))}
                          
                          </Row>
                      </Container>

                      {loading && <p>Loading more images...</p>}

                  </ModalBody>
                  <div className="modal-button">
                  <button onClick={searchImageApi } className="btn  btn-rounded nav-btn-light-blue navbar-btn">Search</button>
                  <Button color="btn btn-rounded btn-red navbar-btn" onClick={toggleImgApiModal}>
                      Cancel
                  </Button>
                  </div>
              </Modal>

              {/* upload a local file */}
              <button onClick={() => fileInput.current.click()} className="btn grow-small btn-rounded nav-btn-light-blue navbar-btn">Upload an Image</button>
              <input ref={fileInput} type="file" accept="image/*" style={{ display: 'none' }} onChange={onFileChange} />

              {/* upload by url */}
              <Modal isOpen={URLModal} toggle={toggleURLModal} centered={true}>
                <ModalBody>
                  <Label className="titan-one-regular" for="urlText">Upload by URL</Label>
                  <Input id="urlText" onChange={e => onURLChange(e)} />
                </ModalBody>
                <div className="modal-button">
                  <button onClick={handleURLSubmit} className="btn grow-small btn-rounded nav-btn-light-blue navbar-btn">Upload</button>
                  <Button color="secondary" onClick={toggleURLModal}>
                    Cancel
                  </Button>
                </div>
              </Modal>

              <button onClick={toggleURLModal} className="btn grow-small btn-rounded nav-btn-light-blue navbar-btn">Upload by URL</button>
            </div>
          </div>
        </section>
      )}
    </Dropzone>
  );
};

export default ImageUploader;